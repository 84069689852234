import axios from 'axios';
import useAuth from '../hooks/useAuth'

// 13.40.140.79
// { baseURL: 'https://13.40.140.79' }scors
// const API = axios.create({ baseURL: 'https://13.40.140.79' });
// const API = axios.create({ baseURL: 'http://13.40.140.79:5000/' });
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';
// axios.defaults.headers.post['"Access-Control-Allow-Headers"'] = "Origin, X-Requested-With, Content-Type, Accept";
const API = axios.create({ baseURL: 'https://api.backseatfleet.co.uk' });
// const API = axios.create({ baseURL: 'https://localhost:5500' });

// API.interceptors.request.use((req) => {
//     // const { auth } = useAuth();
//     // console.log("Intercepter")
//     // req.headers('Access-Control-Allow-Origin: *');
//     req.headers['Access-Control-Allow-Origin'] ='*';
//     // //// console.log("Entered intercepter");
//     // if (sessionStorage.getItem('profile')) {
//     //   //// console.log("Entered intercepter tagging");
//     //   req.headers.Authorization = `Bearer ${JSON.parse(sessionStorage.getItem('profile')).token}`;
//     // } else if ( auth?.token ) {
//     //   req.headers.Authorization = auth?.token
//     // }
//     // req.body.auth = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyUm9sZSI6InVzZXIiLCJ1c2VyTmFtZSI6IndheW5ldGhvbXNvbiIsImNvbXBhbnlOYW1lIjoiU2l0ZUFkbWluIiwidXNlclBhc3N3b3JkSGFzaCI6IiQyYSQxMiRvUjdxWW42R3VyVUI2LkVUcElQNkQuN0h4WENZWUc5c1VDUmhIL0pHanJpU0c3ZXkwTWUvYSIsImlkIjoiNjM5OGU5NDc0Zjg0MGYyYzk4YTBmMDZmIiwiY29tcGFueUlEIjoiNjM5OGU5NDY0Zjg0MGYyYzk4YTBmMDZiIiwiY29zdENlbnRyZXMiOlt7ImNvc3RDZW50cmUiOiJIZWFkIE9mZmljZSIsInZpZXdDb3N0Q2VudHJlIjp0cnVlLCJ2aWV3VXNlcnMiOnRydWUsImVkaXRVc2VycyI6dHJ1ZSwiZGVsZXRlVXNlcnMiOnRydWUsImludml0ZVVzZXJzIjp0cnVlLCJlZGl0Q2FycyI6dHJ1ZSwicmVtb3ZlQ2FycyI6dHJ1ZSwiYWRkQ2FycyI6dHJ1ZSwic3BlbmRDb2lucyI6dHJ1ZSwidmFsaWRhdGVJbnN1cmFuY2UiOnRydWV9XSwiaWF0IjoxNjc0NzgyOTI2LCJleHAiOjE2NzQ3ODY1MjZ9.EiH0Z3zdLlL6DEwMxxQdElqY07-xLINvYsEdl3Dr5AY'

//     return req;
//   });



export const signin = (formData) => API.post(`/users/signin`, formData);
export const signup = (formData) => API.post(`/users/signup`, formData);

export const getLicenceDVLAPage = () => API.get('/licenceDVLA/getTestData');
// Figure out if this is used or if it is redundant!
export const createNewUser = (formData) => API.post('/user/createNewUser', formData);

export const getCompanies = (targetType) => API.post(`/companies/getCompanies`, targetType);
export const getCompanyCostCentres = (companyID) => API.post('/companies/getCompanyCostCentres', companyID);
export const getCompaniesWithCS = () => API.get('/companies/getCompaniesWithCS');
export const getCompany = (id) => API.get(`/companies/getCompany/${id}`);
export const updateCompany = (id, updatedCompany) => API.post(`/companies/updatecompany/${id}`, updatedCompany);
export const createCompany = (newCompany) => API.post('/companies/createCompany', newCompany);
export const deactivateCompany = (id) => API.get(`/companies/deativatecompany/${id}`);
export const deleteCompany = (id) => API.delete(`/companies/deleteCompany/${id}`);
export const getCompanyUsers = (requestDetails) => API.post(`/users/getCompanyUsers`, requestDetails);
export const getCompanyUsersAll = (requestDetails) => API.post(`/users/getCompanyUsersAll`, requestDetails);




export const forceAddCompany = (newCompany) => API.post('/Companies/forceAdd', newCompany);
export const inviteCompany = (newCompany) => API.post('/Companies/invite', newCompany)
export const inviteNewCompany = (newCompany) => API.post('/Companies/inviteNewCompany', newCompany)

export const getUsers = (companyID) => API.post('/users/getUsers', companyID);
export const getUser = (id) => API.get(`/users/getUser/${id}`);
export const deleteUser = (userId) => API.post('/users/deleteUser', userId);
export const createUser = (newUserData) => API.post('/users/createUser', newUserData);



export const getUserDetails = (userID) => API.post('/userDetails/getUserDetails', userID);
export const updateUserDetails = (userDetails) => API.post('/userDetails/updateUserDetails', userDetails);
export const updateCostCentrePerms = (costCentreDetails) => API.post('/userDetails/updateCostCentrePerms', costCentreDetails);
export const getUserPerms = (details) => API.post('/userDetails/getUserPerms', details)


export const getMessages = () => API.get('/messages/getMessages');
export const getMessageInbox = () => API.get('/messages/getMessageInbox');
export const sendMessage = (newMessage) => API.post('/messages/sendMessage', newMessage);

export const createOrder = (orderData) => API.post('/orders/createOrder', orderData);
export const manualCreateOrder = (orderData) => API.post('/orders/manualCreateOrder', orderData);
export const getOrders = (targetType) => API.post('/orders/getOrders', targetType);
export const getOrder = (id) => API.get(`/orders/getOrder/${id}`);
export const companyCreateOrder = (orderData) => API.post('/orders/companyCreateOrder', orderData);

export const getOrderDetails = (orderID) => API.post('/orderDetails/getOrderDetails', orderID)
export const updateOrderDetails = (updateDetails) => API.post('/orderDetails/updateOrderDetails', updateDetails)

export const getUserCars = () => API.get('/cars/getUserCars');
export const getGreyFleet = () => API.get('/cars/getGreyFleet');
export const getCorpFleet = () => API.get('/cars/getCorpFleet');
export const getGreyCar = (id) => API.get(`/cars/getGreyCar/${id}`);
export const getCorpCar = (id) => API.get(`/cars/getCorpCar/${id}`);
export const addGreyCar = (carDetails) => API.post(`/cars/addGreyCar`, carDetails);
export const companyAddGreyCar = (carAndUser) => API.post(`/cars/companyAddGreyCar`, carAndUser);
export const forceAddGreyCar = (carData) => API.post(`/cars/forceAddGreyCar`, carData);
export const addCorpCar = (reg) => API.post(`/cars/addCorpCar`, reg);
export const forceAddCorpCar = (carData) => API.post(`/cars/forceAddCorpCar`, carData);
export const deleteGreyCar = (id) => API.post(`/cars/deleteGreyCar`, id);
export const deleteCorpCar = (id) => API.post(`/cars/deleteCorpCar`, id);
export const updateCorpCar = (id) => API.put(`/cars/updateCorpCar/${id}`);
export const updateGreyCar = (id) => API.put(`/cars/updateGreyCar/${id}`);

export const getCarDetails = (carID) => API.post(`/carDetails/getCarDetails`, carID);
export const getCorpCarDetails = (carID) => API.post(`/carDetails/getCorpCarDetails`, carID);
export const getGreyCarDetails = (carID) => API.post(`/carDetails/getGreyCarDetails`, carID);
export const updateCorpCarMilage = (updateCar) => API.post(`/carDetails/updateCorpCarMilage`, updateCar)
export const updateGreyCarMilage = (updateCar) => API.post(`/carDetails/updateGreyCarMilage`, updateCar)
export const updateCorpCarServiceHistory = (serviceDetails) => API.post(`/carDetails/updateCorpCarServiceHistory`, serviceDetails)
export const updateCorpCarInsuranceHistory = (insuranceDetails) => API.post(`/carDetails/updateCorpCarInsuranceHistory`, insuranceDetails)
export const updateCorpCarAccidentHistory = (accidentDetails) => API.post(`/carDetails/updateCorpCarAccidentHistory`, accidentDetails)
export const updateCarDetails = (newDetails) => API.post('/carDetails/updateCarDetails', newDetails);




export const getDashboardContent = (details) => API.post('/dashboard/getDashboardContent', details);
export const getSelectedDashboardContent = (targetCompany) => API.post('/dashboard/getSelectedDashboardContent', targetCompany);

export const updateDashboard = (targetCompany) => API.post('/updateActions/updateDashboard', targetCompany);
export const updateAllDashboards = () => API.post('/updateActions/updateAllDashboards');
export const createNewDashboard = (targetCompany) => API.post('/updateActions/createNewDashboard', targetCompany);
export const deleteDashboard = (targetCompany) => API.post('/updateActions/deleteDashboard', targetCompany);


export const getTokenHistory = (targetType) => API.post('/tokenHistory/getTokenHistory', targetType)

export const getTokenAmounts = (extraDetails) => API.post('/getTokens/getTokenAmounts', extraDetails)

export const getInvoice = (extraDetails) => API.post('/Invoice/getInvoice', extraDetails)

// Email testing
export const testingEmail = (emailDetails) => API.post('/updateActions/testingEmail', emailDetails) 

// CSV endpoints
export const getLicenceCheckCSV = () => API.get('/licenceCheckCSV/getLicenceCheckCSV')
export const getOrderHistoryCSV = () => API.get('/orderHistoryCSV/getOrderHistoryCSV')
export const getServiceDetailsCSV = () => API.get('/serviceDetailsCSV/getServiceDetailsCSV')
export const getTokenUsageHistoryCSV = () => API.get('/tokenUsageHistoryCSV/getTokenUsageHistoryCSV')
export const getCarsCSV = () => API.get('/carsCSV/getCarsCSV')

// Used in new build
// STANDARD USER ENDPOINTS
export const getUserLicenceDetails = (details) => API.post(`/userDetails/getUserLicenceDetails`, details)
export const addLicenceStart = (details) => API.post(`/userDetails/addLicenceStart`, details)
export const manualyRecheckLicence = (details) => API.post(`/userDetails/manualyRecheckLicence`, details)
export const renewLicenceCheck = (details) => API.post(`/userDetails/renewLicenceCheck`, details)
export const withdrawLicenceCheck = (details) => API.post(`/userDetails/withdrawLicenceCheck`, details)

export const getPersonalVehicles = (auth) => API.post(`/Users/getPersonalVehicles`, auth);
export const getPersonalCompanyVehicles = (details) => API.post(`/Users/getPersonalCompanyVehicles`, details);
export const addPersonalVehicle = (details) => API.post(`/Users/addPersonalVehicle`, details);
export const addPersonalCompanyVehicle = (details) => API.post(`/Users/addPersonalCompanyVehicle`, details);



export const getInsuranceDocument = (details) => API.post(`/Users/getInsuranceDocument`, details);
export const updateInuranceDocument = (details) => API.post(`/Users/updateInuranceDocument`, details)

export const acceptDecline = (details) => API.post(`/Users/acceptDecline`, details)



// User request endpoints
export const userRequestGreyFleet = (details) => API.post(`/Users/userRequestGreyFleet`, details)
export const userRequestGreyFleetRenewal = (details) => API.post(`/Users/userRequestGreyFleetRenewal`, details)


export const getManagementUsers = (details) => API.post(`/users/getManagementUsers`, details);


export const getJWTData = (details) => API.post(`/invite/getJWTData`, details)
export const completeJWTManagerSignup = (details) => API.post(`/invite/completeJWTManagerSignup`, details)

// Invite APIs
export const completeSignupFullCompany = (details) => API.post('/Users/completeSignupFullCompany', details);
export const inviteUser = (newUserData) => API.post('/invite/inviteUser', newUserData);
export const resendUserInvite = (details) => API.post('/invite/resendUserInvite', details);
export const inviteManagementUser = (newUserData) => API.post('/invite/inviteManagementUser', newUserData);
export const signupAuth = (details) => API.post('/users/signupAuth', details)

// Direct message to company
export const sendLandingPageMessage = (details) => API.post(`/SendMessages/landingPage`, details)
export const sendSuggestionMessage = (details) => API.post(`/SendMessages/suggestionMessage`, details)

// Notifications
export const getNotifications = (details) => API.post(`/dashboard/getNotificationData`, details)

// Cost Centre Related APIs
export const getCostCentres = (targetCompany) => API.post('/costCentres/getCostCentres', targetCompany);
export const getCostCentre = (id) => API.get(`/costCentres/getCostCentre/${id}`);
export const createCostCentre = (newCostCentre) => API.post('/costCentres/createCostCentre', newCostCentre);
export const updateCostCentre = (updatedCostCentre) => API.patch('/costCentres/updateCostCentre', updatedCostCentre);
export const deleteCostCentre = (details) => API.post(`/costCentres/deleteCostCentre`, details);

// Dashboard Actions
export const acceptDashboardRequest = (details) => API.post(`/dashboard/acceptRequest`, details)
export const declineDashboardRequest = (details) => API.post(`/dashboard/declineRequest`, details)
export const acceptAddCorpVehicle = (details) => API.post(`/dashboard/acceptAddCorpVehicle`, details)

// News related endpoints
export const updateSingleCompanyNews = (details) => API.post(`/companyDetails/updateSingleCompanyNews`, details)

// Company Details APIs
export const saveCompanySettings = (details) => API.post(`/companyDetails/saveCompanySettings`, details)
export const getCompanySettings = (details) => API.post(`/companyDetails/getCompanySettings`, details)
export const getCompanyDetails = (companyID) => API.post('/companyDetails/getCompanyDetails', companyID)
// Company Documents Modal APIs
export const getCompanyDocuments = (details) => API.post(`/companyDetails/getCompanyDocuments`, details)
export const createNewCompanyDocument = (details) => API.post(`/companyDetails/createNewCompanyDocument`, details)
// Change document status
export const activateDocument = (details) => API.post(`/companyDetails/activateDocument`, details)
export const deactivateDocument = (details) => API.post(`/companyDetails/deactivateDocument`, details)
export const deleteDocument = (details) => API.post(`/companyDetails/deleteDocument`, details)
// Company Contact Details
export const getCompanyContactDetails = (details) => API.post(`/companyDetails/getCompanyContactDetails`, details)
export const updateCompanyContactDetails = (details) => API.post(`/companyDetails/updateCompanyContactDetails`, details)
 // Company Token APIs
export const changeCompanyTokens = (details) => API.post(`/companyDetails/changeCompanyTokens`, changeCompanyTokens)
export const changeCompanyPrices = (details) => API.post(`/companyDetails/changeCompanyPrices`, changeCompanyPrices)

// Policy End Points
export const getUserPolicies = (details) => API.post(`/userDetails/getUserPolicies`, details)
export const agreeUserPolicies = (details) => API.post(`/userDetails/agreeUserPolicies`, details)

// Licence related APIs
export const requestRecheck = (details) => API.post(`/userDetails/requestRecheck`, details)
export const requestLicenceChangeDetails = (details) => API.post(`/userDetails/requestLicenceChangeDetails`, details)
export const licenceChangeDetails = (details) => API.post(`/userDetails/licenceChangeDetails`, details)

// Personal Vehicle Page APIs
export const updatePersonalVehicleCondition = (details) => API.post(`/Users/updatePersonalVehicleCondition`, details)
export const deleteMilageDataPersonalVehicle = (details) => API.post(`/Users/deleteMilageDataPersonalVehicle`, details)
export const getMilageDetailsPersonalVehicle = (details) => API.post(`/Users/getMilageDetailsPersonalVehicle`, details)
export const addMilageDataPersonalVehicle = (details) => API.post(`/Users/addMilageDataPersonalVehicle`, details )
export const getPersonalCompanyVehicle = (details) => API.post(`/Users/getPersonalCompanyVehicle`, details);
export const getPersonalVehicle = (details) => API.post(`/Users/getPersonalVehicle`, details);
export const enablePersonalVehicle = (details) => API.post(`/Users/enablePersonalVehicle`, details)
export const deletePersonalVehicle = (details) => API.post(`/Users/deletePersonalVehicle`, details);
export const disablePersonalVehicle = (details) => API.post(`/Users/disablePersonalVehicle`, details);
export const updatePersonalVehicleNotes = (details) => API.post(`/Users/updatePersonalVehicleNotes`, details)
export const requestExtendedPersonalVehicle = (details) => API.post(`/Users/requestExtendedPersonalVehicle`, details);
export const requestExtendedPersonalVehicleExtension = (details) => API.post(`/Users/requestExtendedPersonalVehicleExtension`, details);
export const requestChangeAutoRen = (details) => API.post(`/Users/requestChangeExtendedAutoRen`, details);
export const requestChangeCorpAutoRen = (details) => API.post(`/Users/requestChangeCorpAutoRen`, details);
export const requestActivateGreyFleet = (details) => API.post(`/Users/requestActivateGreyFleet`, details)
export const requestRenewCorp = (details) => API.post(`/Users/requestRenewCorp`, details)
export const updatePersonalVehicleServiceHistory = (details) => API.post(`/Users/updatePersonalVehicleServiceHistory`, details);
export const requestChangePerExtAutoRen = (details) => API.post(`/Users/requestChangePerExtAutoRen`, details);


// Company Vehicle Page APIs
// Milage Data APIs
export const addMilageDataPersonalCompanyVehicle = (details) => API.post(`/Users/addMilageDataPersonalCompanyVehicle`, details);
export const getMilageDetailsPersonalCompanyVehicle = (details) => API.post(`/Users/getMilageDetailsPersonalCompanyVehicle`, details);
export const deleteMilageDataPersonalCompanyVehicle = (details) => API.post(`/Users/deleteMilageDataPersonalCompanyVehicle`, details);
export const updateCorpVehicleMilage = (details) => API.post(`/Users/updateCorpVehicleMilage`, details);
export const updateCorpVehicleAssigned = (details) => API.post(`/Users/updateCorpVehicleAssigned`, details);


// Change vehicle state
export const enablePersonalCompanyVehicle = (details) => API.post(`/Users/enablePersonalCompanyVehicle`, details);
export const deletePersonalCompanyVehicle = (details) => API.post(`/Users/deletePersonalCompanyVehicle`, details);
export const disablePersonalCompanyVehicle = (details) => API.post(`/Users/disablePersonalCompanyVehicle`, details);
// Service APIs
export const deletePersonalCompanyVehicleServiceHistory = (details) => API.post(`/Users/deletePersonalCompanyVehicleServiceHistory`, details);
export const updateCompanyVehicleServiceHistory = (details) => API.post(`/Users/updateCompanyVehicleServiceHistory`, details);
export const updateCompanyVehicleAssigned = (details) => API.post(`/Users/updateCompanyVehicleAssigned`, details);
export const updateCompanyVehicleAccidentHistory = (details) => API.post(`/Users/updateCompanyVehicleAccidentHistory`, details);
export const updatePersonalVehicleAccidentHistory = (details) => API.post(`/Users/updatePersonalVehicleAccidentHistory`, details);

// Insurance APIs
export const updateCompanyVehicleTradePolicy = (details) => API.post(`/Users/updateCompanyVehicleTradePolicy`, details);
export const updateCompanyInsurance = (details) => API.post(`/Users/updateCompanyInsurance`, details);
export const updatePersonalInsurance = (details) => API.post(`/Users/updatePersonalInsurance`, details);
export const getPendingCorpInsuranceDoc = (details) => API.post(`/Users/getPendingCorpInsuranceDoc`, details);
export const getPendingGreyInsuranceDoc = (details) => API.post(`/Users/getPendingGreyInsuranceDoc`, details);
export const getCurrentCorpInsuranceDoc = (details) => API.post(`/Users/getCurrentCorpInsuranceDoc`, details);
export const getCurrentGreyInsuranceDoc = (details) => API.post(`/Users/getCurrentGreyInsuranceDoc`, details);
export const getPreviousCorpInsuranceDoc = (details) => API.post(`/Users/getPreviousCorpInsuranceDoc`, details);
export const getPreviousGreyInsuranceDoc = (details) => API.post(`/Users/getPreviousGreyInsuranceDoc`, details);
export const acceptCorpInsuranceDoc = (details) => API.post(`/Users/acceptCorpInsuranceDoc`, details);
export const acceptGreyInsuranceDoc = (details) => API.post(`/Users/acceptGreyInsuranceDoc`, details);
export const rejectCorpInsuranceDoc = (details) => API.post(`/Users/rejectCorpInsuranceDoc`, details);
export const rejectGreyInsuranceDoc = (details) => API.post(`/Users/rejectGreyInsuranceDoc`, details);



export const updateCompanyVehicleCondition = (details) => API.post(`/Users/updateCompanyVehicleCondition`, details)
export const updateCompanyVehicleNotes = (details) => API.post(`/Users/updateCompanyVehicleNotes`, details);

// Generic Vehicle APIs
export const updateTargetCarDVLA = (details) => API.post(`/Users/updateTargetCarDVLA`, details)

// Admin APIs
export const updateVehicleAdminOptions = (details) => API.post(`/Users/updateVehicleAdminOptions`, details)

// Update APIs
export const updateAllCars = (details) => API.post('/updateActions/updateAllCars', details)
export const updateCars = (companyID) => API.post('/updateActions/updateCars', companyID)
export const updateAllLicences = (details) => API.post('/updateActions/updateAllLicences', details)
export const updateLicences = (companyID) => API.post('/updateActions/updateLicences', companyID)

export const updateManagerPersonalVehicles = (details) => API.post(`/users/updateManagerPersonalVehicles`, details);
export const updateManagerPersonalVehicle = (details) => API.post(`/users/updateManagerPersonalVehicle`, details);
export const updateManagerCompanyVehicles = (details) => API.post(`/users/updateManagerCompanyVehicles`, details);
export const updateManagerCompanyVehicle = (details) => API.post(`/users/updateManagerCompanyVehicle`, details);

// Ticket APIs
export const getTicket = () => API.get('/tickets/getTicket');

export const getTicketDetails = (details) => API.post('/ticketDetails/getTicketDetails', details)
export const updateTicketDetails = (details) => API.post('/ticketDetails/updateTicketDetails', details)

export const getTickets = (details) => API.post('/tickets/getTickets', details);
export const createTicket = (details) => API.post('/tickets/createTicket', details);
export const updateTicket = (details) => API.post(`/tickets/updateTicket`, details);

export const getCompanyTicket = () => API.get('/tickets/getCompanyTicket');
export const getCompanyTickets = (details) => API.post('/tickets/getCompanyTickets', details);
export const createCompanyTicket = (details) => API.post('/tickets/createCompanyTicket', details);
export const updateCompanyTicket = (details) => API.post(`/tickets/updateCompanyTicket`, details);

// Employee Settings Endpoint
export const getEmployeeSettings = (details) => API.post(`/userDetails/getEmployeeSettings`, details)
export const updateEmployeeSettings = (details) => API.post(`/userDetails/updateEmployeeSettings`, details)
export const requestEmployeeDeactivation = (details) => API.post(`/userDetails/requestEmployeeDeactivation`, details)
export const requestEmployeeDeletion = (details) => API.post(`/userDetails/requestEmployeeDeletion`, details)
export const manualyCompleteEmployeeSetup = (details) => API.post(`/userDetails/manualyCompleteEmployeeSetup`, details)

// New API Strucutre

// Landing Page Related Endpoints
export const addNewCompany = (postData) => API.post(`/landpageEndpoints/addNewCompany`, postData);

// Licence Endpoints
export const updateLicenceAutoRenewal = (postData) => API.post(`/licenceEndpoints/changeAutoRenewal`, postData);
export const updateEmployeeLicenceDetails = (postData) => API.post(`/licenceEndpoints/updateEmployeeLicenceDetails`, postData);
export const recheckEmployeeLicenceDetails = (postData) => API.post(`/licenceEndpoints/recheckEmployeeLicenceDetails`, postData);

// Company Vehicle Endpoints
export const getCompanyVehicles = (postData) => API.post(`/companyVehiclesEndpoints/getCompanyVehicles`, postData);
export const addNewCompanyVehicle = (postData) => API.post(`/companyVehicleEndpoints/addNewCompanyVehicle`, postData);

// Personal Vehicle Endpoints
export const getPersonalVehiclesB = (postData) => API.post(`/personalVehiclesEndpoints/getPersonalVehicles`, postData);
export const addNewPersonalVehicle = (postData) => API.post(`/personalVehiclesEndpoints/addNewPersonalVehicle`, postData);

// Employees Endpoints
export const inviteFullEmployee = (postData) => API.post(`/employeesDetailsEndpoints/inviteFullEmployee`, postData);
export const invitePartialEmployee = (postData) => API.post(`/employeesDetailsEndpoints/invitePartialEmployee`, postData);
export const inviteNoneDriverEmployee = (postData) => API.post(`/employeesDetailsEndpoints/inviteNoneDriverEmployee`, postData);
export const inviteManager = (postData) => API.post(`/employeesDetailsEndpoints/inviteManager`, postData);

// Employee Endpoints
export const manualyCompleteEmployeeSignup = (postData) => API.post(`/employeeDetailsEndpoints/manualyCompleteEmployeeSignup`, postData);
export const activateNoneDriverFull = (postData) => API.post(`/employeeDetailsEndpoints/activateNoneDriverFull`, postData);
export const activateNoneDriverPartial = (postData) => API.post(`/employeeDetailsEndpoints/activateNoneDriverPartial`, postData);
export const activateNoneDriverComplete = (postData) => API.post(`/employeeDetailsEndpoints/activateNoneDriverComplete`, postData);

// Manager Endpoints
export const updateManagerDetails = (postData) => API.post(`/managerDetailsEndpoints/updateManagerDetails`, postData);
export const updateManagerPassword = (postData) => API.post(`/managerDetailsEndpoints/updateManagerPassword`, postData);

// Company Manager Endpoints
export const companyUpdateManagerDetails = (postData) => API.post(`/companyManagerEndpoints/updateManagerDetails`, postData);
export const companyUpdateManagerCostcentre = (postData) => API.post(`/companyManagerEndpoints/updateManagerCostcentre`, postData);
export const companyUpdateManagerPerms = (postData) => API.post(`/companyManagerEndpoints/updateManagerPerms`, postData);
export const companyUpdateManagerPassword = (postData) => API.post(`/companyManagerEndpoints/updateManagerPassword`, postData);
export const companySendManagerPassword = (postData) => API.post(`/companyManagerEndpoints/sendManagerPassword`, postData);
export const companyActivateManager = (postData) => API.post(`/companyManagerEndpoints/activateManager`, postData);
export const companyDeactivateManager = (postData) => API.post(`/companyManagerEndpoints/deactivateManager`, postData);
export const companyDeleteManager = (postData) => API.post(`/companyManagerEndpoints/deleteManager`, postData);

// Company House Endpoints
export const getCompanyHouseDetails = (postData) => API.post(`\companyHouse\getCompanyHouseDetails`, postData);







