import { START_COMPANY_TICKET_LOADING, END_COMPANY_TICKET_LOADING, FETCH_ALL_COMPANY_TICKETS, FETCH_COMPANY_TICKET, CREATE_COMPANY_TICKET } from '../constants/actionTypes';

export default (state = { isLoading: true, ticketsCompany: [] }, action) => {
    switch (action.type) {
        case START_COMPANY_TICKET_LOADING:
            return {...state, isLoading: true};
        case END_COMPANY_TICKET_LOADING:
            return {...state, isLoading: false};
        case FETCH_ALL_COMPANY_TICKETS:
            return { ...state, ticketsCompany: action.payload };
        case FETCH_COMPANY_TICKET:
            return { ...state, ticketsCompany: action.payload };
        case CREATE_COMPANY_TICKET:
            return { ...state, ticketsCompany: action.payload };
        default:
            return state;
    }
};


