import { START_POLICES_LOADING, END_POLICIES_LOADING, FETCH_POLICES } from '../constants/actionTypes';


export default (state = { isLoading: true, companyPolicies: [] }, action) => {
    switch (action.type) {
        case START_POLICES_LOADING:
            return { ...state, isLoading: true };
        case END_POLICIES_LOADING:
            return { ...state, isLoading: false };
        case FETCH_POLICES:
            return { ...state, companyPolicies: action.payload};
        case 'CLEAR_COMPANY_POLICIES':
            return { ...state, companyPolicies: []}        
        default:
            return state;
    }
};