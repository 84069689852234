import * as api from '../api';
import { AUTH, LOGOUT, NEWUSER, START_LOGIN_LOADING, END_LOGIN_LOADING, CLEAR_AUTH_DATA } from '../constants/actionTypes';


export const signin = (formData, navigate) => async (dispatch) => {
    try {
        
        dispatch({ type: START_LOGIN_LOADING });
        const { data } = await api.signin(formData);        
        dispatch({ type: AUTH, payload: data });
        
    } catch (error) {
        dispatch({ type: AUTH, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_LOGIN_LOADING });
};

export const redirectUser = (navigate) => async (dispatch) => {
    try {
        // console.log("got here")
        navigate('/testURL/');
    } catch (error) {
        // console.log(error.messsage);
        navigate('/');
    }

}

export const signup = (formData, navigate) => async (dispatch) => {
    try {
        dispatch({ type: CLEAR_AUTH_DATA });

        const { data } = await api.signup(formData);

        dispatch({ type: AUTH, data });

        navigate('/NextSteps');
    } catch (error) {
        dispatch({ type: AUTH, payload: { message: 'Failure', error: error?.response?.data } });
    }
};

export const createNewUser = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signup(formData);
        dispatch({ type: CLEAR_AUTH_DATA });

        dispatch({type: NEWUSER, payload: data});

        // CHANGE THIS TO COMPANY USER PANEL
        navigate('/');
    } catch (error) {
        dispatch({ type: NEWUSER, payload: { message: 'Failure', error: error?.response?.data } });
    }
};

export const signupAuth = (details) => async (dispatch) => {
    try {
        dispatch({ type: START_LOGIN_LOADING });
        const { data } = await api.signupAuth(details);
        
        dispatch({ type: AUTH, payload: data });
        
        
    } catch (error) {
        dispatch({ type: AUTH, payload: { message: 'Failure', error: error?.response?.data } });
    }
    dispatch({ type: END_LOGIN_LOADING });
}
