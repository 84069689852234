import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { AuthProvider } from './components/NewAuth/AuthProvider';
import { reducers } from './reducers';
// import ErrorBoundry from './ErrorBoundry'
import { config } from 'dotenv'; 
import HttpsRedirect from 'react-https-redirect';

import App from './App';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { SnackbarProvider, MaterialDesignContent  } from 'notistack';

const store = createStore(reducers, compose(applyMiddleware(thunk)));



// notistack-snackbar
// Adds the <App> component to the DOM
ReactDOM.render(
    <HttpsRedirect>
    <Provider store={store} maxWidth={false}>
        <BrowserRouter>
            <AuthProvider>
                    <Routes >
                        <Route sensitive={false} path="/*" element={
                            <SnackbarProvider 
                                maxSnack={3}
                            >
                                <App />
                            </SnackbarProvider>
                        } />
                    </Routes>
            </AuthProvider>
        </BrowserRouter>
    </Provider>
    </HttpsRedirect>,
    document.getElementById('root')
);

