import { START_LOADING_LICENCE_ENDPOINT, END_LOADING_LICENCE_ENDPOINT, POST_LICENCE_ENDPOINT_DATA, FETCH_LICENCE_ENDPOINT_DATA, CLEAR_LICENCE_ENDPOINT_DATA } from '../constants/actionTypes';

export default (state = { isLoading: false, licenceEndpoints: [] }, action) => {
    switch (action.type) {
        case START_LOADING_LICENCE_ENDPOINT:
            return { ...state, isLoading: true };
        case END_LOADING_LICENCE_ENDPOINT:
            return { ...state, isLoading: false };;
        case POST_LICENCE_ENDPOINT_DATA:
            return { ...state, licenceEndpoints: action.payload };
        case FETCH_LICENCE_ENDPOINT_DATA:
            return { ...state, licenceEndpoints: action.payload };
        case CLEAR_LICENCE_ENDPOINT_DATA:
            return { ...state, licenceEndpoints: []}
        default:
            return state;
    };
};