import React, { useState } from 'react'
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';

const RequireAuth =  ({ allowedRole }) => {
    const params = useParams()
    const { auth } = useAuth();
    const location = useLocation();
    const dispatch = useDispatch();
    const { enqueueSnackbar  } = useSnackbar();
    
    let tokenValid;

    function isTokenExpired(token) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    }

    try {
        tokenValid = isTokenExpired(auth?.token)
        if (tokenValid) {
            dispatch({ type: 'LOGOUT' });
            enqueueSnackbar('Session expired.', { variant: 'error' });
            return <Navigate to="/login" state={{ from: location }} replace/>
        };
    } catch (error) {

    }


    let disable = false;

    return (
        <>
        {
            // User exists and if does does it have right role
            auth?.userName ? auth?.role === allowedRole || disable ?
            <Outlet context={auth} />
            :
            <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace/>
        }
        </>                    
    );
}

export default RequireAuth;