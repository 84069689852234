import { Box, Grid, Typography, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ButtonR, ButtonRP } from '../LandingPage/Button/ButtonElements'
import { ArrowForward, ArrowRight } from '../LandingPage/GettingStarted/gettingStartedElements' 
import Controls from '../../PageProps/Controls/Controls'
import { Form, useForm } from '../../PageProps/useForm'
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// Dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CookiePolicy, PrivacyNotice, TermsAnddConditions } from '../LandingPage/Footer/FooterModals';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCompany } from '../../../actions/companySignupEndpoints';
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} sx={{ zIndex: 2500 }} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
}));

const initialFValues = {
    // Company details
    companyFullName: 'Test New Comp',
    companyDotString: 'TestTradingN',
    tradingName: 'TestDotStr',
    companyHouse: '12345678',
    estimatedVehicles: 44,
    estimatedEmployees: 44,
    contactName: 'Person',

    // Company Address
    buildingNumber: '4',
    streetName: 'street name',
    location: 'town',
    postcode: 'pr27dz',

    // Company Contact
    companyEmail: 'waynethomson5@gmail.com',
    notificationEmail: 'waynethomson5@gmail.com',
    companyPhone: '1234',

    // Admin Account
    adminUsername: 'adminUsername',
    adminPassword: 'Password123!',
    repeatAdminPassword: 'Password123!',

    // Agree
    agreeTerms: true,
    agreeCookie: true,
    agreePrivacy: true
}

const Register = ({ auth, darkMode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, companySignupEndpoints } = useSelector((state) => state.companySignupEndpoints);
    const [hover, setHover] = useState(false)
    const [returnHover, setReturnHover] = useState(false);

    const [ severityMessage, setSeverityMessage ] = useState('');
    const [ popupText, setPopupText ] = useState('');
    const [ open, setOpen ] = useState(false);

    const [isPrivOpen, setIsPrivOpen] = useState(false)
    const [isCookieOpen, setIsCookieOpen] = useState(false)
    const [isTermsOpen, setIsTermsOpen] = useState(false)

    const [showPassword, setShowPassword] = useState(false)

    const [ openConfirmRegister, setOpenConfirmRegister ] = useState(false);

    const [ clickedConfirmRegister, setClickedConfirmRegister ] = useState(false);

    console.log('companySignupEndpoints')
    console.log(companySignupEndpoints)
    
    const onHover = () => {
        setHover(!hover)
    };

    const onReturnHover = () => {
        setReturnHover(!returnHover)
    };

    const validation = (fieldValues = values) => {
        let temp = {...errors}
        
        // Company 
        if ('companyFullName' in fieldValues) {
            temp.companyFullName = fieldValues?.companyFullName ? "" : "Required";
        };

        if ('tradingName' in fieldValues) {
            temp.tradingName = fieldValues?.tradingName ? "" : "Required";
        };
                
        if ('companyDotString' in fieldValues) {
            temp.companyDotString = (/^[a-zA-Z0-9]([a-zA-Z0-9]){3,40}[a-zA-Z0-9]$/).test(fieldValues.companyDotString) ? "" : "Can only be a-z with no spaces or other characters";
        };

        if ('companyHouse' in fieldValues) {
            temp.tradingName = fieldValues?.tradingName ? "" : "Required";
        };

        if ('estimatedVehicles' in fieldValues) {
            temp.tradingName = fieldValues?.tradingName ? "" : "Required";
        };

        if ('estimatedEmployees' in fieldValues) {
            temp.tradingName = fieldValues?.tradingName ? "" : "Required";
        };

        if ('contactName' in fieldValues) {
            temp.contactName = fieldValues?.contactName ? "" : "Required";
        };



        if ('companyEmail' in fieldValues) {
          temp.companyEmail = (/^\S+@\S+\.\S+$/).test(fieldValues.companyEmail) ? "" : "Enter a valid email address";
          if (temp.companyEmail === "") {
              temp.companyEmail =  fieldValues.companyEmail.length < 40 ? "" : "Must be less than 40 characters";
          }
        };

        if ('notificationEmail' in fieldValues) {
            temp.notificationEmail = fieldValues.notificationEmail ? 'trig' : ''
            if (temp.notificationEmail === 'trig') {
                temp.notificationEmail =  (/^\S+@\S+\.\S+$/).test(fieldValues.notificationEmail) ? "" : "Enter a valid email address";
            };
        };
        

        // Location
        if ('buildingNumber' in fieldValues) {
            temp.buildingNumber = fieldValues?.buildingNumber ? "" : "Cannot Proceed Without Agreement";
        };

        if ('streetName' in fieldValues) {
            temp.streetName = fieldValues?.streetName ? "" : "Cannot Proceed Without Agreement";
        };

        if ('location' in fieldValues) {
            temp.location = fieldValues?.location ? "" : "Cannot Proceed Without Agreement";
        };

        if ('postcode' in fieldValues) {
            temp.postcode = fieldValues?.postcode === 'ERROR' || fieldValues?.postcode === 'Error' || fieldValues?.postcode === 'error' ? "" : "cont";
            if (temp.postcode === 'cont') {
                temp.postcode = (/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/).test(fieldValues?.postcode) ? '' : 'Invalid postcode';
            };
        };

        
        
        // Admin Account adminUsername
        if ('adminUsername' in fieldValues) {
            temp.adminUsername = fieldValues.adminUsername ? "" : "This field is required";            
            if (temp.adminUsername === '') {
                temp.adminUsername = (/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/).test(fieldValues.adminUsername) ? '' : 'Must start and end with a letter or number, can contain dot (.), undescore (_) and hyyphen (-), but cannot be consecutive and be 5-20 characters.';
            };
        }; 
        if ('adminPassword' in fieldValues) {
            temp.adminPassword = (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^(){}:;'~<>,.\\\/])[A-Za-z\d@$!%*#?&^(){}:;'~<>,.\\\/]{8,}$/).test(fieldValues.adminPassword) ? '' : 'Must be eight characters, at least one letter, one number and one special character (@$!%*#?&^(){}:;/\'~<>,.).\/)';
            if (temp.adminPassword === "") {
                temp.adminPassword = fieldValues.adminPassword === values.repeatAdminPassword  ? "" : "Passwords must match";
            }
        }
        if ('repeatAdminPassword' in fieldValues) {
            if (temp.repeatAdminPassword === "") {
                temp.repeatAdminPassword = fieldValues.repeatAdminPassword === values.adminPassword  ? "" : "Passwords must match";
            }
        }


        // Agreements

        if ('agreeTerms' in fieldValues) {
            temp.agreeTerms = fieldValues?.agreeTerms ? "" : "Cannot Proceed Without Agreement";
        };

        if ('agreeCookie' in fieldValues) {
            temp.agreeCookie = fieldValues?.agreeCookie ? "" : "Cannot Proceed Without Agreement";
        };

        if ('agreePrivacy' in fieldValues) {
            temp.agreePrivacy = fieldValues?.agreePrivacy ? "" : "Cannot Proceed Without Agreement";
        };
          
        setErrors({
            ...temp
        })
        if (fieldValues == values) {
          return Object.values(temp).every(x => x === "");
        }
        return true
    };

    const deactivated = true;
    
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validation);

    const colorOne = darkMode ? '#D3D3D3' : '#fcfcfc';

    const testingStuff = () => {
        console.log(testingStuff)
    }

    const confirmRegistration = () => {
        if (validation()) {
            setOpenConfirmRegister(true);
        }
    };

    const registerNewCompany = () => {
        if (validation()) {
            dispatch(addNewCompany({ auth: auth?.token, values: values }));
        };
        setOpenConfirmRegister(false);
        setClickedConfirmRegister(!clickedConfirmRegister);
    };

    const navigateToCompletePage = () => {
        navigate('/RegistrationComplete', {replace: true});
        setClickedConfirmRegister(false);
    };

    useEffect(() => {
        if (companySignupEndpoints?.message && clickedConfirmRegister) {
            if (companySignupEndpoints?.message === 'Successfully added new company') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Successfully Added New Company');
                setOpen(true);
                navigateToCompletePage();
            } else if (companySignupEndpoints?.message === 'invalid input') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Invalid Inputs');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'Not agreed terms') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Not Agreed to Terms');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'Existing Company Number') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Company Number Already Registered');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'Existing Company Dot String') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Exsiting Company Login');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'failed to create new company') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Company');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'failed to create cost centre') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Initial Cost Centre');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'failed to create company admin') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Company Admin User');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'Total failure') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Company New Company');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'Failure') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Company New Company');
                setOpen(true);
                setClickedConfirmRegister(false);
            } else if (companySignupEndpoints?.message === 'failed to create company admin') {
                setOpen(false);
                setSeverityMessage('success');
                setPopupText('Failed to Create Company Admin User');
                setOpen(true);
                setClickedConfirmRegister(false);
            };
            setClickedConfirmRegister(false);
        };
    },[companySignupEndpoints]);

    const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        };  
        setOpen(false);
      };

    return (
        <Box m='20px'>
            
        
        <Grid container display='flex' width='100%' justifyContent={'center'} alignContent='center' alignItems={'center'} justifyItems='center'>
            <Grid item xs={11} md={8} container bgcolor='#f2f0f0' borderRadius={1.5} boxShadow={3} justifyContent='center' alignItems='center' alignContent={'center'} justifyItems='center' textAlign='center'>
                {
                    deactivated ? 
                    <>
                        <Box sx={{ textAlign: 'center'}} pb={3} pt={3}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '2em', pb: '3vh' }}>BACKSEAT FLEET</Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1.875em' }}>COMING SOON (MAY 2025)</Typography>
                            <ButtonRP to='/' onMouseEnter={onHover} onMouseLeave={onHover} primary={false} dark={false}>
                                Return to Home Page {hover ? <ArrowForward /> : <ArrowRight />}
                            </ButtonRP>
                        </Box>
                    </>
                    :
                    <>
                            <Grid item xs={12} mt={1}>
                                <Box>
                                    <Typography variant='h3' fontWeight={700} mb={2}>BACKSEAT FLEET</Typography>
                                    <Typography variant='h5' fontWeight={700} mb={2}>Register New Account</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} mb={2}>
                                <Typography variant='h7' fontWeight={300} >Company Details</Typography>
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='companyFullName'
                                    label="Company Full name"
                                    value={values.companyFullName}
                                    onChange={handleInputChange}
                                    error={errors.companyFullName}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`This is the name your company is registed with on companies house and will not be displayed to employees.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='tradingName'
                                    label="Trading Name"
                                    value={values.tradingName}
                                    onChange={handleInputChange}
                                    error={errors.tradingName}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`This is the name your company trades as and will be visible to employees.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='companyDotString'
                                    label="Login Text"
                                    value={values.companyDotString}
                                    onChange={handleInputChange}
                                    error={errors.companyDotString}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`This is the text attached to the end of a username on login. e.g Username.CompanyX, where username and company Login Text combine to create a user login.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='companyHouse'
                                    label="Company House No."
                                    value={values.companyHouse}
                                    onChange={handleInputChange}
                                    error={errors.companyHouse}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`This is your companies registered company house number.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='estimatedVehicles'
                                    label="Estimated Vehicles (Optional)"
                                    value={values.estimatedVehicles}
                                    onChange={handleInputChange}
                                    error={errors.estimatedVehicles}
                                    variant={'filled'}
                                    type={'number'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The estimated number of vehicles that will be used within the app (optional).`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='estimatedEmployees'
                                    label="Estimated Employees (Optional)"
                                    value={values.estimatedEmployees}
                                    onChange={handleInputChange}
                                    error={errors.estimatedEmployees}
                                    variant={'filled'}
                                    type={'number'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The estimated number of employees that will be used within the app (optional).`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} mb={2}>
                                <Typography variant='h7' fontWeight={300} >Company Address</Typography>
                            </Grid>
                            
                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='buildingNumber'
                                    label="Building Number/Name"
                                    value={values.buildingNumber}
                                    onChange={handleInputChange}
                                    error={errors.buildingNumber}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The building number or name of your companies registered location.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='streetName'
                                    label="Street Name"
                                    value={values.streetName}
                                    onChange={handleInputChange}
                                    error={errors.streetName}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The street name of your companies registered location.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='location'
                                    label="Town/City"
                                    value={values.location}
                                    onChange={handleInputChange}
                                    error={errors.location}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The village/town/city of your companies registered location.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} mb={2}>
                                <Controls.Input  
                                    name='postcode'
                                    label="Postcode"
                                    value={values.postcode}
                                    onChange={handleInputChange}
                                    error={errors.postcode}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The postcode of your companies registered location. Type "ERROR" to bypass validation if you have issues with a valid postcode.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>                            
                            
                            <Grid item xs={12} mb={2}>
                                <Typography variant='h7' fontWeight={300} >Contact Information</Typography>
                            </Grid>
                            
                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='companyEmail'
                                    label="Company Email"
                                    value={values.companyEmail}
                                    onChange={handleInputChange}
                                    error={errors.companyEmail}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The primary email address for your company. By default all emails will be sent to this email address.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='companyPhone'
                                    label="Phone Number (Optional)"
                                    value={values.companyPhone}
                                    onChange={handleInputChange}
                                    error={errors.companyPhone}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The phone number of your company (optional).`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='notificationEmail'
                                    label="Notification Email (Optional)"
                                    value={values.notificationEmail}
                                    onChange={handleInputChange}
                                    error={errors.notificationEmail}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`If specified notifications will be sent to this email address (optional).`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='contactName'
                                    label="Contact Name"
                                    value={values.contactName}
                                    onChange={handleInputChange}
                                    error={errors.contactName}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`Name of the person who would be the point of contact for this account.`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                
                            </Grid>

                            <Grid item xs={12} mb={2}>
                                <Typography variant='h7' fontWeight={300} >Admin Account</Typography>
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='adminUsername'
                                    label="Admin Username"
                                    value={values.adminUsername}
                                    onChange={handleInputChange}
                                    error={errors.adminUsername}
                                    variant={'filled'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <InputAdornment position="end" >
                                        <IconButton edge="end" tabIndex={-1}>
                                            <HtmlTooltip
                                            title={
                                                <>
                                                {`The username for your company admin account. Your login will be ${values?.companyDotString ? 'Username.' + values?.companyDotString : 'Username.' + 'LoginText'}`}
                                                </>
                                            }
                                            sx={{ zIndex: "10551" }}
                                            >
                                            <HelpIcon />
                                            </HtmlTooltip>
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='adminPassword'
                                    label="Password"
                                    value={values.adminPassword}
                                    onChange={handleInputChange}
                                    error={errors.adminPassword}
                                    variant={'filled'}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <>
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                                                {showPassword === false ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            <InputAdornment position="end">
                                                <IconButton edge="end" tabIndex={-1}>
                                                <HtmlTooltip
                                                    title={
                                                    <>
                                                        {`Password for your admin account. Min 8 characters with a minimum of one uppercase, one number and one symbol(@$!%*#?&^(){}:;/\'~<>,.).\/).`}
                                                    </>
                                                    }
                                                    sx={{ zIndex: "10551" }}
                                                >
                                                    <HelpIcon />
                                                </HtmlTooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        </>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} mb={2}>
                                <Controls.Input  
                                    name='repeatAdminPassword'
                                    label="Repeat Password"
                                    value={values.repeatAdminPassword}
                                    onChange={handleInputChange}
                                    error={errors.repeatAdminPassword}
                                    variant={'filled'}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                    endAdornment: (                  
                                        <>
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                                                {showPassword === false ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            <InputAdornment position="end">
                                                <IconButton edge="end" tabIndex={-1}>
                                                <HtmlTooltip
                                                    title={
                                                    <>
                                                        {`Repeat admin password. Min 8 characters with a minimum of one uppercase, one number and one symbol(@$!%*#?&^(){}:;/\'~<>,.).\/).`}
                                                    </>
                                                    }
                                                    sx={{ zIndex: "10551" }}
                                                >
                                                    <HelpIcon />
                                                </HtmlTooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        </>
                                    ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box display={'flex'}>
                                    <Button onClick={() => setIsTermsOpen(!isTermsOpen)}>Agree to terms and conditions</Button>
                                    <Controls.Checkbox
                                        name={"agreeTerms"}
                                        value={values.agreeTerms}
                                        onChange={handleInputChange}
                                        error={errors.agreeTerms}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box display={'flex'}>
                                    <Button onClick={() => setIsPrivOpen(!isPrivOpen)}>Agree to privacy notice</Button>
                                    <Controls.Checkbox
                                        name={"agreePrivacy"}
                                        value={values.agreePrivacy}
                                        onChange={handleInputChange}
                                        error={errors.agreePrivacy}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box display={'flex'}>
                                    <Button onClick={() => setIsCookieOpen(!isCookieOpen)}>Agree to cookies policy</Button>
                                    <Controls.Checkbox
                                        name={"agreeCookie"}
                                        value={values.agreeCookie}
                                        onChange={handleInputChange}
                                        error={errors.agreeCookie}
                                    />
                                </Box>
                            </Grid>
                            


                            <Grid item xs={6} md={3} display={'flex'} mb={2}>
                                <ButtonR onClick={confirmRegistration} onMouseEnter={onHover} onMouseLeave={onHover}>Complete Sign Up {hover ? <ArrowForward /> : <ArrowRight />}</ButtonR>
                            </Grid>
                            
                            <Grid item xs={6} md={3} display={'flex'} mb={2}>
                                <ButtonRP to='/' onMouseEnter={onReturnHover} onMouseLeave={onReturnHover} primary={false} dark={false}>
                                    Return to Home Page {returnHover ? <ArrowForward /> : <ArrowRight />}
                                </ButtonRP>
                            </Grid>
                        
                    </>
                }

            </Grid>
        </Grid>

            <PrivacyNotice isPrivOpen={isPrivOpen} setIsPrivOpen={setIsPrivOpen} isCookieOpen={isCookieOpen} setIsCookieOpen={setIsCookieOpen} />
            <TermsAnddConditions isTermsOpen={isTermsOpen} setIsTermsOpen={setIsTermsOpen} />
            <CookiePolicy isCookieOpen={isCookieOpen} setIsCookieOpen={setIsCookieOpen} />

            <div>
                <Dialog
                open={openConfirmRegister}
                onClose={() => setOpenConfirmRegister(!openConfirmRegister)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Registration"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure the details you have entered are correct and you'd like to proceed with registration of your company?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: 'red' }} onClick={() => setOpenConfirmRegister(!openConfirmRegister)}>Cancel</Button>
                    <Button sx={{ color: 'green' }} onClick={registerNewCompany} >Complete Registration</Button>
                </DialogActions>
                </Dialog>
            </div>

            <Snackbar open={open} autoHideDuration={3500} onClose={handleSnackClose} sx={{ zIndex: 99999 }}>
                <Alert 
                    onClose={handleSnackClose}
                    severity=
                    {
                    severityMessage
                    } 
                    sx={{ width: '100%', zIndex: 9999001 }}>
                    {
                    popupText
                    }
                </Alert>
            </Snackbar>

        </Box>
  )
}

export default Register