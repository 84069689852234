import React, { useState } from 'react'
import { Grid, Box } from '@mui/material'
//import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Modal from '@mui/joy/Modal';
import TextField from '@mui/joy/TextField';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { styled } from "@mui/material/styles";


// Items for expanding list objects
import ListSubheader from '@mui/material/ListSubheader';
//import { List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import MuiList from '@mui/material/List';

const List = styled(MuiList)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'red',
    '&, & .MuiListItemIcon-root': {
      color: 'pink',
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'orange',
    '&, & .MuiListItemIcon-root': {
      color: 'yellow',
    },
  },
});


export const PrivacyNotice = ({ isPrivOpen, setIsPrivOpen, isCookieOpen, setIsCookieOpen }) => {
    const [openSectionOne, setOpenSectionOne] = useState(false);
    const [openSectionTwo, setOpenSectionTwo] = useState(false);
    const [openSectionThree, setOpenSectionThree] = useState(false);
    const [openSectionFour, setOpenSectionFour] = useState(false);
    const [openSectionFive, setOpenSectionFive] = useState(false);
    const [openSectionSix, setOpenSectionSix] = useState(false);
    const [openSectionSeven, setOpenSectionSeven] = useState(false);
    const [openSectionEight, setOpenSectionEight] = useState(false);
    const [openSectionNine, setOpenSectionNine] = useState(false);
    const [openSectionTen, setOpenSectionTen] = useState(false);
    const [openSectionEleven, setOpenSectionEleven] = useState(false);

    //   <ListSubheader component="div" id="nested-list-subheader">
    //     List sub header
    //   </ListSubheader>
    // }
    const openCookiesModal = () => {
      setIsPrivOpen(!isPrivOpen)
      setIsCookieOpen(!isCookieOpen)
    }

    

    return (
        <Modal open={isPrivOpen} onClose={() => setIsPrivOpen(false)} sx={{ alignContent: 'right', }} >
            <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{
            width: '97vw',
            height: '97vh',
            borderRadius: 'md',
            bgcolor: 'white',
            p: 3,
            boxShadow: 'lg',
            overflowY: "scroll",
            '&::-webkit-scrollbar': {display: 'none'}            
          }}
        >
          <ModalClose />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
          >
            Privacy Notice - Details
          </Typography>
          <Grid container direction="row"
  justifyContent="center"
  alignItems="center">

          <List
      sx={{ width: '100%', maxWidth: 1100, bgcolor: 'background.paper'}}
      component="div"
      aria-labelledby="nested-list-subheader"
      
        >

          <ListItemButton onClick={() => setOpenSectionOne(!openSectionOne)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Information we collect from our users" />
            {openSectionOne ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionOne} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, 
            
            }} >
            <Typography component='h1' sx={{ marginBottom: 3 }}>Information given</Typography>
            <Typography sx={{ marginBottom: 3 }}>This is information that you voluntarly give us by filling in forms on our website, corresponding with us by phone, e-mail or face-to-face. It includes information you provide when you register to use our site, subscribe to our service, 
                  search for a product, place an order on our site, participate in discussion boards or other social media functions on our site, and when you report a problem with our site 
            </Typography>
            <Typography sx={{ marginBottom: 3 }}>The personal information you give us such as name, address, e-mail, phone number, user names and passwords. For this Backseat Fleet Ltd can be considered the data controller.</Typography>
            <Typography sx={{ marginBottom: 3 }}>Aditionally, you may provide vehicle/s and driver/s related data, contact preferences and other related information within our services, for this Backseat Fleet Ltd is the data processor and you, the client, are considered the data controller.</Typography>
            <Typography sx={{ marginBottom: 3 }}>We use your data to respond to your enquiries about our services (the lawful basis for this is “Legitimate Interest”) and to provide our services to you (the lawful basis for this is “Contract”).</Typography>
            <Typography component='h1'  sx={{ marginBottom: 3 }}>Information Clected</Typography>
            <Typography sx={{ marginBottom: 3 }}>On each visit to our website we may collect the following information, necessary for our side the function:</Typography>
            <Typography component='li' sx={{ paddingLeft: 1, marginBottom: 3 }}>
                  Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;
                  Information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain 
                  pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.
            </Typography>

            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionTwo(!openSectionTwo)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Cookies we use" />
            {openSectionTwo ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionTwo} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>
            <Typography>
                Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us 
                to improve our site. For detailed information on the cookies we use and the purposes for which we use them see 
                our <Typography component='span' onClick={openCookiesModal} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Cookie policy</Typography>.	
            </Typography>

            </List>
          </Collapse>



          <ListItemButton onClick={() => setOpenSectionThree(!openSectionThree)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="How we use the information we collect from you" />
            {openSectionThree ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionThree} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='h1' sx={{ marginBottom: 3 }}>Information you give us</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>We will use this information:</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about where you have given us permission to do so or where you have not indicated that you do not want us to use your data in this way;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you where you have given us permission to do so. If you are an existing customer, we will only contact you by -e-mail with information about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to you. 
                If you are a new customer, and where we permit selected third parties to use your data, we (or they) will contact you by electronic means only if you have knowingly and clearly consented to this;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To notify you about changes to our service;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Backseat Fleet processes your and your drivers' personal data in order to organise, administer and run an online software programme which is accessible via 
                the website. More specifically, Backseat Fleet uses your information to communicate with you via e-mail and, if necessary, phone regarding your Backseat Fleet account;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Backseat Fleet may also use your personal data to gain a better understanding regarding who uses the Backseat Fleet website and how they use it,
                 including the web pages they access and pages that are unused so as to be able to improve the website;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Occasionally, Backseat Fleet may use your information to contact you for market research products and services, but in such a case will only be via e-mail and clearly marked to indicate that it
                is wholly optional.</Typography>
                <Typography component='h1'>Information we collect about you</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>We will use this information:</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To improve our site to ensure that content is presented in the most effective manner for you and for your computer;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>To allow you to participate in interactive features of our service, when you choose to do so;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>As part of our efforts to keep our site safe and secure;c</Typography>
                <Typography component='li' sx={{ marginLeft: 1, marginBottom: 3 }}>As part of our efforts to keep our site safe and secure;</Typography>
                <Typography component='li' sx={{ marginBottom: 3 }}>Backseat Fleet does not collect extra information about its visitors from other sources, such as public records or bodies, 
                or private organisations. If Backseat Fleet wishes to use your personal data for a new purpose, other than for which it was originally supplied, Backseat Fleet will ask you first.</Typography>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionFour(!openSectionFour)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Disclosure of your information" />
            {openSectionFour ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionFour} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='p' sx={{ marginBottom: 3 }}>We may share your personal information with:</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Selected third parties supplying back office services;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Selected third parties supplying licence checkingc, if you have selected to use these services;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>We may on occasions suggest to you selected third party providers that may be of interest to you offering a service which 
                enhances the Backseat Fleet software, but we will not share your data with third party providers without your consent;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third-party or as part of any business restructuring or 
                reorganisation, in which event we will take steps to ensure that your privacy rights under this Privacy Notice continue to be protected;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of supply and other agreements; or 
                to protect the rights, property, or safety of Backseat Fleet, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction;</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>We may also share your personal information to third parties for the purposes of the prevention or detection of crime or fraud.</Typography>

            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionFive(!openSectionFive)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Where and how we store your personal information" />
            {openSectionFive ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionFive} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

              <Typography component='p' sx={{ marginBottom: 3 }}>Any information you enter into the Backseat Fleet software, or information we enter on your behalf in the Backseat Fleet software, will be stored within systems located in the UK and therefore 
              subject to applicable data protection laws.  We will not store any of this information outside of the UK.</Typography>
              <Typography component='p' sx={{ marginBottom: 3 }}>Any information that you send to us which is not held within the Backseat Fleet software, for instance sent in emails to our support desk, may be stored by our third party provider on Amazon Web Services (AWS) 
              in the UPDATE WHEN DEPLOY. We have specific contracts in place with our third party provider, approved for use in the UK, which give personal data the same protection it has in the UK.</Typography>
              <Typography component='p' sx={{ marginBottom: 3 }}>All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using SSL technology. Where we have given you (or where you have chosen) a password that enables 
              you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</Typography>
              <Typography component='p' sx={{ marginBottom: 3 }}>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to 
              our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</Typography>
              <Typography component='p' sx={{ marginBottom: 3 }}>All sensitive data stored by us or stored with third parties on our behalf, data at rest, is encrypted to ensure greater levels of protection.</Typography>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionSix(!openSectionSix)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Your duty to information us of changes in your information" />
            {openSectionSix ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionSix} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. Including permission you grant us or your company. You reserve the right to 
                withdraw any permissions granted, but must inform us of your desire to withdraw.</Typography>

            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionSeven(!openSectionSeven)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Your rights to access the personal information we hold" />
            {openSectionSeven ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionSeven} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='h1' sx={{ marginBottom: 3 }}>Your Rights, Your Data</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>Under certain circumstances, by law you have the right to:</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Request access to your personal information (commonly known as a "data subject access request"). This enables you to receive a copy of the personal
                 information we hold about you and to check that we are lawfully processing it.</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Request correction of the personal information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Request erasure of your personal information. This enables you to ask us to delete or remove personal information where there is no good reason for us continuing to process it. 
                You also have the right to ask us to delete or remove your personal information where you have exercised your right to object to processing (see below).</Typography>
                <Typography component='li' sx={{ marginLeft: 1, }}>Object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which
                 makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.</Typography>
                <Typography component='li' sx={{ marginLeft: 1}}>Request the restriction of processing of your personal information. This enables you to ask us to suspend the processing of personal information about you, for example if you want 
                us to establish its accuracy or the reason for processing it.</Typography>
                <Typography component='li' sx={{ marginLeft: 1, marginBottom: 3}}>Request the transfer of your personal information to another party.</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>If you want to review, verify, correct or request erasure of your personal information, object to the processing of your personal data, or request that we transfer a copy of your personal information 
                to another party, please contact Our Data Protection Officer in writing at support@backseatfleet.co.uk or at the following address:</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>Backseat Fleet Limited | Company number: 14374228 | Reg address: 3 Maple Leaf Close, Preston, Lancashire, PR2 7DZ</Typography>

            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionEight(!openSectionEight)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Complaints" />
            {openSectionEight ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionEight} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='p' sx={{ marginBottom: 3 }}>Backseat Fleet Ltd is committed to delivering a great service to our customers and our team aims to solve issues in a rapid mannor. If for any reason you are unhappy with our staff, services or business practices please contact
                our team at Support@BackseatFleet.co.uk and we will do everything in our power to help.</Typography>
                <Typography>If you think our collection or use of personal data is unfair, misleading or inappropriate or if you have concerns about the security of your personal data, you also have the right to make a complaint to the Information Commissioner's Office at the following address: Information Commissioner's
                 Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF or via their website at <a href='https://ico.org.uk'>https://ico.org.uk</a></Typography>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionNine(!openSectionNine)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Links from our site to third party websites" />
            {openSectionNine ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionNine} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>
                <Typography component='h1' sx={{ marginBottom: 3 }}>Third Party Links</Typography>
                <Typography>Several links can be found on our site to other, third party websites for you convenience and to keep you information of required information. Use of these links will take you outside of our site. When you access third party websites you understand and accept that Backseat Fleet Ltd has 
                no control over the content and operation of these sites and is not responsible for the privacy practices, or other operations, of that site. Backseat Fleet Ltd reccomends reading related agreements of these third parties before using their services. Privacy Policy does 
                not cover the information practices of those third party websites linked to the website. These other websites may send their own cookies to users, collect data, or solicit personal information.</Typography>

            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionTen(!openSectionTen)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Security" />
            {openSectionTen ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionTen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='p' sx={{ marginBottom: 3 }}>To prevent unauthorized access, maintain accuracy, and ensure proper use of information, Backseat Fleet Ltd has employed physical, electronic, and managerial processes including username and password protection of your account to safeguard and secure the information Backseat Fleet Ltd collects online. 
                Backseat Fleet Ltd does not give visitors to the website the option of other secure transmission methods to send Backseat Fleet Ltd their personal data, unless otherwise indicated. Accordingly, your attention is drawn to the fact that information carried over the Internet is not
                 guaranteed as secure. Information could be intercepted, lost, redirected, changed and possibly read by third parties.</Typography>
                <Typography component='p' sx={{ marginBottom: 3 }}>All Backseat Fleet Ltd employees and data processors who have access to and are associated with the processing of personal data, are obliged to repect the confidentiality of such personal data.</Typography>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setOpenSectionEleven(!openSectionEleven)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 1}}>
            <ListItemText primary="Changes to this Privacy Policy" />
            {openSectionEleven ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionEleven} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='p' sx={{ marginBottom: 3 }}>From time to time changes to our policies may occur, but changes to the Backseat Fleet Ltd Data Protection Policy (and hence this Privacy Notice) include any significantly different use of 
                your personal data, we will let you know and give you the option to agree to the new use or to withdraw from any agreements without charge should you choose to do so.</Typography>

            </List>
          </Collapse>

        </List>
        </Grid >          
          
        </ModalDialog>
        </Modal>
    )
}


export const CookiePolicy = ({ isCookieOpen, setIsCookieOpen }) => {
    const [openSectionOne, setOpenSectionOne] = useState(false);

    

    return (
      <Modal open={isCookieOpen} onClose={() => setIsCookieOpen(!isCookieOpen)} sx={{ alignContent: 'center', }} >
      <ModalDialog
    aria-labelledby="basic-modal-dialog-title"
    aria-describedby="basic-modal-dialog-description"
    sx={{
      width: '97vw',
      height: '97vh',
      borderRadius: 'md',
      bgcolor: 'white',
      p: 3,
      boxShadow: 'lg',
      overflowY: "scroll",
      '&::-webkit-scrollbar': {display: 'none'}
      
    }}
  >
                  <ModalClose position='fixed'/>
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
          >
            Cookies - Details
          </Typography>
          <Grid container direction="row"
  justifyContent="center"
  alignItems="center">
          <List
      sx={{ width: '100%', maxWidth: '95vw', bgcolor: 'background.paper', '@media (min-width: 800px)': {width: '70vh'}}}
      component="div"
      aria-labelledby="nested-list-subheader"
      
    >
  <Typography component='h1' sx={{ marginBottom: 3 }}>Information About Our Cookies</Typography>
  <Typography component='p' sx={{ marginBottom: 3 }}>Our website uses cookies to distinguish you from other users of our website. 
  This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.</Typography>
    <Typography component='p' sx={{ marginBottom: 3 }}>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</Typography>
    <Typography component='p' sx={{ marginBottom: 3 }}>Some of the following cookies may be used:</Typography>

            <ListItem sx={{ marginLeft: 1, marginBottom: 3 }}>
              <Typography >Strictly necessary cookies: These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, 
              use a shopping cart or make use of e-billing services;</Typography>
            </ListItem>
            <ListItem sx={{ marginLeft: 1, marginBottom: 3 }}>
              <Typography component='p'>Strictly necessary cookies: These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, 
              use a shopping cart or make use of e-billing services;</Typography>
            </ListItem>
            <ListItem sx={{ marginLeft: 1, marginBottom: 3 }}>
              <Typography component='p'>Analytical/performance cookies: They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, 
              for example, by ensuring that users are finding what they are looking for easily;</Typography>
            </ListItem>
            <ListItem sx={{ marginLeft: 1, marginBottom: 3 }}>
              <Typography component='p'>Functionality cookies: These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice 
              of language or region).</Typography>
            </ListItem>
            <ListItem sx={{ marginLeft: 1, marginBottom: 3 }}>
              <Typography component='p'>Targeting cookies: These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website more relevant to your interests.</Typography>
            </ListItem>

          <ListItemButton onClick={() => setOpenSectionOne(!openSectionOne)} sx={{ bgcolor: '#22303c', color: '#fff', marginBottom: 3}}>
          <ListItemText primary="Enhanced View of Cookies Used" />
          {openSectionOne ? <RemoveIcon /> : <AddIcon />}
          </ListItemButton>
          <Collapse in={openSectionOne} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 3, }}>

                <Typography component='p' sx={{ marginBottom: 3 }}>At this time we do not use cookies. Session data is stored within the application memory and therefore does not require cookies. We currently use no analytical cookies, but may add some in the future
                should it be deemed benifial for ethier the company or users. If at some point cookies are added further details can be found here and a popup requesting consent will be presented.</Typography>

            </List>
          </Collapse>
          <ListItem sx={{ marginBottom: 3 }}>
              <Typography >You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) 
              you may not be able to access all or parts of our site in the future, however at this time we do not use cookies.</Typography>
          </ListItem>

    </List>
    </Grid>
  </ModalDialog>
  </Modal>
    )
}


export const TermsAnddConditions = ({ isTermsOpen, setIsTermsOpen }) => {
    return (
        <Modal open={isTermsOpen} onClose={() => setIsTermsOpen(false)} sx={{ alignContent: 'center', }}>
            <ModalDialog
          aria-labelledby="basic-modal-dialog-title"
          aria-describedby="basic-modal-dialog-description"
          sx={{
      width: '97vw',
      height: '97vh',
      borderRadius: 'md',
      bgcolor: 'white',
      p: 3,
      boxShadow: 'lg',
      overflowY: "scroll",
      '&::-webkit-scrollbar': {display: 'none'}
      
    }}
        >
        <ModalClose />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
          >
            Terms and Conditions - Details
          </Typography>
          <Typography
            id="basic-modal-dialog-description"
            mt={0.5}
            mb={2}
            textColor="text.tertiary"
          >
          </Typography>

            <Grid container direction="row"
  justifyContent="center"
  alignItems="center" >
                <Grid container direction='row' sx={{ width: '60vw' }}>
                      <Grid item sm={12} md={6}>
                      <Typography component='h1' display='block'>Terms And Conditions <Typography component='span' sx={{ color: 'lightgray', fontSize: 10 }}>v2.37</Typography></Typography>
                      <Typography component='h3' display='block'>1 Basis of sale</Typography>
                      <Typography component='p' display='block'>1.1 “Applicable Law” means as applicable and binding on the Customer, Backseat Fleet and/or in respect of the provision of the Software and Services:</Typography>
                      <Typography component='p' display='block'>(a) any law, statue, regulation, byelaw or subordinate legislation in force from time to time to which a party is subject and/or in any jurisdiction that the Software or Services is provided to or in respect of;</Typography>
                      <Typography component='p' display='block'>(b) the common law and laws of equity as applicable to the parties from time to time;</Typography>
                      <Typography component='p' display='block'>(c) any binding court order, judgement or decree; or</Typography>
                      <Typography component='p' display='block'>(d) any applicable direction, policy rule or order that is binding on a party and that is made or given by any regulatory body having jurisdiction over a party or any of that party's assets, resources or business.</Typography>
                      <Typography component='p' display='block'>1.2 “Applicable Data Protection Laws” means (i) to the extent the UK GDPR applies, the law of the United Kingdom or of a part of the United Kingdom which relates to the protection of personal data; and (ii) 
                      to the extent the EU GDPR applies, the law of the European Union or any member state of the European Union to which Backseat Fleet is subject, which relates to the protection of Personal Data.</Typography>
                      <Typography component='p' display='block'>1.3 “Conditions” means these terms and conditions as amended from time to time.</Typography>
                      <Typography component='p' display='block'>1.4 “Controller, Data Subject, Personal Data, Processor and processing” shall have the respective meanings given to them in the UK GDPR from time to time (and related expressions, 
                      including “process, processed, processing, and processes” shall be construed accordingly);</Typography>
                      <Typography component='p' display='block'>1.5 “Customer” means the person or firm who purchases the Services from Backseat Fleet.</Typography>
                      <Typography component='p' display='block'>1.6 “Customer Data” means the data inputted by the Customer, or Backseat Fleet on the Customer's behalf, for the purpose of using the Services or facilitating the Customer's use of the Services, 
                      including any Protected Data.</Typography>
                      <Typography component='p' display='block'>1.7 “EU GDPR” means the General Data Protection Regulation (EU) 2016/679;</Typography>
                      <Typography component='p' display='block'>1.8 “Backseat Fleet” means Backseat Fleet Limited (company number 14374228).</Typography>
                      <Typography component='p' display='block'>1.9 “Intellectual Property Rights” means patents, rights to inventions, copyright and neighbouring and related rights, moral rights, trademarks and service marks, business names and domain names, 
                      rights in get-up and trade dress, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information 
                      (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to 
                      claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.</Typography>
                      <Typography component='p' display='block'>“Order Confirmation” means the pro-forma signed by the Customer listing the customer details and Services to be supplied in accordance with these Conditions.</Typography>
                      <Typography component='p' display='block'>1.10 “Parties” shall mean (1) Backseat Fleet and (2) the Customer.</Typography>
                      <Typography component='p' display='block'>1.11 “Protected Data” means Personal Data received from or on behalf of the Customer in connection with the performance of the Backseat Fleets' obligations.</Typography>
                      <Typography component='p' display='block'>1.13 “Services” mean the following services to be provided by Backseat Fleet:
                                - Backseat Fleet Software for the Function Purchased, specifications of which will be supplied in the Order Confirmation;
                                - Technical Support, details of which can be supplied on demand; and
                                - Additional Services, any additional services requested by the Customer and agreed by Backseat Fleet.</Typography>
                      <Typography component='p' display='block'>1.14 “Software” means Backseat Fleet vehicle and licence management support software.</Typography>
                      <Typography component='p' display='block'>1.15 “Sub-Processor” means any agent, subcontractor or other third party (excluding its employees) engaged by FBackseat Fleet for carrying out any processing activities on behalf of
                       the Customer in respect of the Protected Data.</Typography>
                      <Typography component='p' display='block'>1.16 “UK GDPR” has the meaning given to it in the Data Protection Act 2018.</Typography>
                      <Typography component='p' display='block'>1.17 The Conditions and the Order Confirmation (together the “Agreement”) shall form the entire agreement between the Parties in respect of the supply of the Services.</Typography>
                      <Typography component='p' display='block'>1.18 These Conditions apply to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</Typography>
                   
                      <Typography component='h3' display='block'>2 Duration and Termination</Typography>
                      <Typography component='p' display='block'>2.1 This Agreement shall commence when the Order Confirmation is recieved by the customer and dated by the Backseat Fleet and shall continue, unless terminated earlier in accordance with clause 2.3, until the tokens are used by 
                      the customer to provide the service ascribed to the token.</Typography>
                      <Typography component='p' display='block'>2.2 The Agreement is that a purchased token performs its described function once the token function is actioned that token shall be considered complete.</Typography>
                      <Typography component='p' display='block'>2.3 Any token unused 36 months after purchase shall become void and considered complete.</Typography>
                      <Typography component='p' display='block'>2.4 Backseat Fleet agrees that, unless terminated earlier in accordance with clause 2.3, that it will provide the ascribed token service for customer purchased tokens at which time said token/s will be considered complete
                      and Backseat Fleet Obligations will be also considered complete for the completed token/s.</Typography>
                      
                      <Typography component='h3' display='block'>4 Specification and alterations</Typography>
                      <Typography component='p' display='block'>4.1 Any descriptive matter or advertising issued by Backseat Fleet and any descriptions of the Services issued or published for the sole purpose of giving an approximate idea of the Services described in 
                      them shall not form part of the Agreement or have any contractual force.</Typography>
                      <Typography component='p' display='block'>4.2 Backseat Fleet reserves the right to change the specification and content of the Software and Services at each completed Agreement.</Typography>
                      <Typography component='p' display='block'>4.3 In the event of Backseat Fleet being unable to provide the Services of the same nature and quality specified prior to the date hereof , Backseat Fleet shall provide 14 days written 
                      notice to the Customer who may at any time in this period terminate this Agreement.</Typography>
                      <Typography component='p' display='block'>4.4 In the event of a termination by the Customer, and not a result of Backseat Fleet excercising clause 4.3, the Customer then the Customer is not entitled to a return of funds unless 
                      Backseat Fleet are obligeted by law.</Typography>
                      
                      <Typography component='h3' display='block'>5 Obligations and warranties</Typography>
                      <Typography component='p' display='block'>5.1 Backseat Fleet warrants that it will use reasonable care and skill in providing the Services.</Typography>
                      <Typography component='p' display='block'>5.2 Backseat Fleet:</Typography>
                      <Typography component='p' display='block'> (a) does not warrant that:</Typography>
                      <Typography component='p' display='block'>(i) the Customer;s use of the Services will be uninterrupted or error-free; or</Typography>
                      <Typography component='p' display='block'>(ii) that the Services and/or the information obtained by the Customer through the Services will meet the Customer's requirements.</Typography>
                      <Typography component='p' display='block'>(b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the Customer 
                      acknowledges that the Services may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</Typography>
                      <Typography component='p' display='block'>5.3 In the event of any loss or damage to Customer Data, the Customer;s sole and exclusive remedy against Backseat Fleet shall be for Backseat Fleet to use reasonable commercial endeavours to restore the lost 
                      or damaged Customer Data from the latest back-up of such Customer Data maintained by Backseat Fleet. Backseat Fleet shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party (except those third 
                      parties sub-contracted by Backseat Fleet to perform services related to Customer Data maintenance and back-up for which it shall remain fully liable.</Typography>
                      <Typography component='p' display='block'>5.4 If during the term of this Agreement the Customer wishes to access historic Customer Data it must submit a request in writing to Backseat Fleet. Backseat Fleet shall use reasonable commercial endeavours to deliver the 
                      relevant back-up to the Customer within 30 days of its receipt of such a written request, provided that the Customer shall pay all reasonable expenses incurred by Backseat Fleet in providing the back-up of Customer Data.</Typography>
                      <Typography component='p' display='block'>5.5 The Customer shall:
                              (a)provide the Supplier with:
                              (i) all necessary co-operation in relation to this Agreement; and
                              (ii) all necessary access to such information as may be required by Backseat Fleet in order to provide the Services;</Typography>
                      <Typography component='p' display='block'>(b) without affecting its other obligations under this agreement, comply with all Applicable Laws and regulations with respect to its activities under this Agreement.</Typography>
                      <Typography component='h3' display='block'> 6 Payment Terms</Typography>
                      <Typography component='p' display='block'>6.1 All prices stated are exclusive of VAT unless otherwise stated.</Typography>
                      <Typography component='p' display='block'>6.2 Backseat Fleet retains ownership of Backseat Fleet Software.</Typography>
                      <Typography component='p' display='block'>6.3 The Customer is not obligated to complete a placed order, as such Backseat Fleet does not provide for purchase services unless payment for said services is fully completed.</Typography>
                      <Typography component='p' display='block'>6.4 The Customer can withdraw from any purchase prior to invoice settlement without notice and without charge.</Typography>
                      <Typography component='p' display='block'>6.5 Backseat Fleet shall be entitled to charge additional fees for any unquoted Additional Services required and agreed by the Customer. </Typography>
                      <Typography component='p' display='block'>The refusal by Backseat Fleet to carry out such Additional Services shall not be construed as a breach of this Agreement.</Typography>
                      <Typography component='p' display='block'>6.6 Backseat Fleet reserves the right to:</Typography>
                      <Typography component='p' display='block'>(a) Increase the cost of services provided, but not for inprogress orders.</Typography>
                      <Typography component='p' display='block'>(b) Cancel in progress orders that have been in an unpaid state for more than 31 days.</Typography>
                      
                      <Typography component='h3' display='block'> 7 Liability</Typography>
                      <Typography component='p' display='block'>7.1 This clause 7 sets out the entire financial liability of Backseat Fleet (including any liability for the acts or omissions of its employees, agents and sub-contractors) 
                      to the Customer in respect of:</Typography>
                      <Typography component='p' display='block'>(a) any breach of this Agreement;</Typography>
                      <Typography component='p' display='block'>(b) any use made by the Customer of the Services; and</Typography>
                      <Typography component='p' display='block'>(c) any representation, statement or tortious act or omission (including negligence) arising under or in connection with this Agreement.</Typography>
                      <Typography component='p' display='block'>7.2 All warranties, conditions and other terms implied by statute or common law are, to the fullest extent permitted by law, excluded from this Agreement.</Typography>
                      <Typography component='p' display='block'>7.3 Nothing in this Agreement shall exclude or restrict Backseat Fleets liability for:</Typography>
                      <Typography component='p' display='block'>(a) death or personal injury arising from the negligence of Backseat Fleets or its employees while acting in the course of their employment; or</Typography>
                      <Typography component='p' display='block'>(b) for any damage or liability incurred by the Customer as a result of fraud or fraudulent misrepresentation by Backseat Fleet.</Typography>
                      <Typography component='p' display='block'>7.4 Subject to clause 7.2 and clause 7.3:</Typography>
                      <Typography component='p' display='block'>(a) Backseat Fleet shall not be liable to the Customer, whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation or otherwise for:</Typography>
                      <Typography component='p' display='block'>(i) loss of profits; or</Typography>
                      <Typography component='p' display='block'>(ii) loss of business; or</Typography>
                      <Typography component='p' display='block'>(iii) depletion of goodwill and/or similar losses; or</Typography>
                      <Typography component='p' display='block'>(iv) loss of anticipated savings; or</Typography>
                      <Typography component='p' display='block'>(v) loss of goods; or</Typography>
                      <Typography component='p' display='block'>(vi) loss of contract; or</Typography>
                      <Typography component='p' display='block'>(vii) loss of use; or</Typography>
                      <Typography component='p' display='block'>(viii) loss of or corruption of data or information; or</Typography>
                      <Typography component='p' display='block'>(ix) any special, indirect, consequential or pure economic loss, costs, damages, charges or expenses.</Typography>
                      <Typography component='p' display='block'>(b) Backseat Fleet shall not be liable to the Customer, whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation or otherwise in relation to:</Typography>
                      <Typography component='p' display='block'>(i) any misinformation supplied to Backseat Fleet by the Customer or any third party; or</Typography>
                      <Typography component='p' display='block'>(ii) the booking of services repairs and MOT testing; or</Typography>
                      <Typography component='p' display='block'>(iii) any aspect of the implementation of the Customer's health and safety policy and risk assessment policy; or</Typography>
                      <Typography component='p' display='block'>(iv) negligence by Customer employees in the operation of Customer company vehicles; or</Typography>
                      <Typography component='p' display='block'>(v) any aspect of the implementation of the Customer company vehicle policy; or</Typography>
                      <Typography component='p' display='block'>(vi) any breakdown or failure of equipment supplied by Backseat arising from its incompatibility with equipment which has not been previously supplied or recommended by Backseat Fleet; or</Typography>
                      <Typography component='p' display='block'>(vii) any delay in or failure to perform all or part of its obligations under the terms of this Agreement if such a delay or failure shall be due to act of god strikes lockouts laboured services statute 
                      order or any regulations of any government public local or other authority delays or defaults of suppliers and sub-contractors and without prejudice to the generality of the foregoing any other causes beyond the reasonable control of Backseat Fleet; 
                      or</Typography>
                      <Typography component='p' display='block'>(viii) any data input errors, either by the user or supplied to Backseat Fleet by the Customer for initial data upload; or,</Typography>
                      <Typography component='p' display='block'>(ix) failure of the Software to save data, either by user error or system failure.</Typography>
                      <Typography component='p' display='block'>(c) Subject to 7.4 (a) and 7.4 (b) above, Backseat Fleet total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, 
                      restitution or otherwise arising in connection with the performance or contemplated performance of this Agreement shall be limited to the Token Value that caused the afore mentioned.</Typography>
                      <Typography component='p' display='block'>7.5 The provisions of this clause 7 shall continue to apply notwithstanding the termination of this Agreement.</Typography>
                      
                      <Typography component='h3' display='block'>8 Suspension of Service</Typography>
                      <Typography component='p' display='block'>8.1 Backseat Fleet may at its sole discretion elect to suspend forthwith the provision of the Services until further notice without liability to the Customer on notifying the 
                      Customer either orally (confirming the same in writing) or in writing in the event that:</Typography>
                      <Typography component='p' display='block'>(a) The Customer is in material breach of its obligations under this Agreement and (if such breach is remediable) fails to remedy that breach within 21 days after receipt of notice in writing to do so; or</Typography>
                      <Typography component='p' display='block'>(b) The Customer is suspected in Backseat Fleets reasonable opinion of involvement with fraud or attempted fraud in connection with the use of the Services.</Typography>
                      
                      <Typography component='h3' display='block'>9. Intellectual Property Rights</Typography>
                      <Typography component='p' display='block'>9.1 Backseat Fleet and its licensors shall retain ownership of all Intellectual Property Rights in or arising out of or in connection with the Software and the Services 
                      (other than Intellectual Property Rights in any materials provided by the Customer.</Typography>
                      <Typography component='p' display='block'>9.2 Backseat Fleet grants to the Customer or shall procure the direct grant to the Customer of, non-exclusive, royalty-free licence during the term of the Agreement to use the Software.
                       If this Agreement is terminated, this licence will automatically terminate.</Typography>
                      <Typography component='p' display='block'>9.3 The Customer shall not sub-licence, assign or otherwise transfer the rights granted by clause 9.2.</Typography>

                      <Typography component='h3' display='block'>10. Data Protection</Typography>
                      <Typography component='p' display='block'>10.1 Both parties will comply with all applicable requirements of the Applicable Data Protection Laws. This clause 10 is in addition to, and does not relieve, remove or replace, a party's 
                      obligations or rights under the Applicable Data Protection Laws.</Typography>
                      <Typography component='p' display='block'>10.2 The parties acknowledge that for the purposes of the Applicable Data Protection Laws, the Customer is the Controller and Backseat Fleet is the Processor in respect of the Protected Data. Schedule 1 sets out the scope, 
                      nature and purpose of processing by Backseat Fleet, the duration of the processing and the types of personal data and categories of Data Subject.</Typography>
                      <Typography component='p' display='block'>10.3 Where Backseat Fleet is processing any Personal Data in connection with this Agreement in the capacity of a Controller, the Customer consents to (and shall procure all required consents, from its personnel, 
                      representatives and agents, in respect of) all actions taken by Backseat Fleet in connection with the processing of such Personal Data, provided these are in compliance with the then-current version of Backseat Fleets privacy policy available on its website. 
                      In the event of any inconsistency or conflict between the terms of the Privacy Policy and this Agreement, the Privacy Policy will take precedence.10.4 Without prejudice to the generality of clause 10.1, the Customer warrants, represents and undertakes,
                       that:</Typography>                
                      
                    </Grid>
                    
                    
                    
                    
                    
                    
                    <Grid item sm={12} md={6} sx={{ paddingTop: 10 }}>
                      
                      
                      
                      
                      <Typography component='p' display='block'>(a) it has all necessary appropriate consents and notices in place to enable lawful transfer of the Protected Data to Backseat Fleet for the duration and purposes of the Agreement;</Typography>
                      <Typography component='p' display='block'>(b) all instructions given by the Customer to Backseat Fleet in respect of the Protected Data shall at all times be in accordance with Applicable Data Protection Laws; and</Typography>
                      <Typography component='p' display='block'>(c) The Customer is satisfied that:</Typography>
                      <Typography component='p' display='block'>(i) Backseat Fleet processing operations are suitable for the purposes for which the Customer propose to use the Software and Services and engage Backseat Fleet to process the Protected Data; and</Typography>
                      <Typography component='p' display='block'>(ii) Backseat Fleet has sufficient expertise, reliability and resources to implement technical and organisational measures that meet the requirements of Applicable Data Protection Laws.</Typography>
                      <Typography component='p' display='block'>10.5 The Customer shall indemnify and keep indemnified the Backseat Fleet against all losses, claims, damages, liabilities, fines, sanctions, interest, penalties, costs, charges, expenses, compensation paid to 
                      Data Subjects, demands and legal and other professional costs (calculated on a full indemnity basis and in each case whether or not arising from any investigation by, or imposed by, a supervisory authority) arising out of or in connection with any 
                      breach by the Customer of its obligations under this clause 10.</Typography>
                      <Typography component='p' display='block'>10.6 Without prejudice to the generality of clause 10.1, Backseat Fleet shall, in relation to the Protected Data:</Typography>
                      <Typography component='p' display='block'>(a) process that Protected Data only on the documented instructions of the Customer, unless Backseat Fleet is required by Applicable Laws to otherwise process that Protected Data. Where Backseat Fleet is relying on 
                      Applicable Laws as the basis for processing Protected Data, Backseat Fleet shall notify the Customer of this before performing the processing required by the Applicable Laws unless those Applicable Laws prevent Backseat Fleet from doing so on important grounds 
                      of public interest. Backfleet Fleet shall inform the Customer if, in its opinion, the instructions of the Customer infringe Applicable Data Protection Laws</Typography>
                      <Typography component='p' display='block'>(b) implement the technical and organisational measures as set out in Schedule 1 Part B, reviewed and approved by the Customer, to protect the Protected Data against unauthorised, unlawful or accidental destruction, 
                      loss, alteration, disclosure or access;</Typography>
                      <Typography component='p' display='block'>(c) ensure that all personnel who have access to and/or process Protected Data are obliged to keep the Protected Data confidential; and</Typography>
                      <Typography component='p' display='block'>(d) only transfer Protected Data outside of the UK if required under this Agreement and provided that all such transfers are effected in accordance with Applicable Data Protection Laws. For these purposes, 
                      the Customer shall promptly comply with any reasonable request of Backseat Fleet, including any request to enter into standard data protection clauses adopted by the EU Commission from time to time (where the EU GDPR applies to the transfer) or adopted 
                      by the Commissioner from time to time (where the UK GDPR applies to the transfer.</Typography>
                      <Typography component='p' display='block'>10.7 Backseat Fleet shall:</Typography>
                      <Typography component='p' display='block'>(a) assist the Customer insofar as is possible (taking into account the nature of the processing and the information available to Backseat Fleet), at the Customer's cost, in responding to any reasonable request from a Data 
                      Subject and in ensuring the Customer's compliance with its obligations under Applicable Data Protection Laws with respect to security, breach notifications, impact assessments and consultations with supervisory authorities or regulators;</Typography>
                      <Typography component='p' display='block'>(b) notify the Customer within 2 days of becoming aware of a personal data breach affecting the Customer's account or Protected Data;</Typography>
                      <Typography component='p' display='block'>(c) at the written direction of the Customer, delete or return Protected Data and copies thereof to the Customer on termination of the Agreement unless required by Applicable Law to continue to process that Protected Data. 
                      For the purposes of this clause 9.7(c) Protected Data shall be considered deleted where it is put beyond further use by Backseat Fleet; and</Typography>
                      <Typography component='p' display='block'>(d) maintain complete and accurate records and information to demonstrate its compliance with this clause 10.</Typography>
                      <Typography component='p' display='block'>10.8 The Customer can contact Backseat Fleet for any issues relating to data protection at support@backseatfleet.co.uk.</Typography>
                      <Typography component='p' display='block'>10.9 Without prejudice to clause 10.6, if Backseat Fleet believes that any instruction received by it from the Customer is likely to infringe Applicable Data Protection Laws it shall promptly inform the Customer and be 
                      entitled to cease to provide the Services until the parties have agreed appropriate amended instructions which are not infringing.</Typography>
                      <Typography component='p' display='block'>10.10 The Customer hereby provides its prior, general authorisation for Backseat Fleet to:</Typography>
                      <Typography component='p' display='block'>(a) use the Sub-Processors already engaged by Backseat Fleet as at the date of the Agreement (Backseat Fleet shall make available to the Customer a list of all Sub-Processors authorised to process the Protected Data 
                      (Sub-Processor List) on requestk) and appoint further Sub-Processors, provided that Backseat Fleet:</Typography>
                      <Typography component='p' display='block'>(i) shall ensure that the terms on which it appoints such Sub-Processors comply with Applicable Data Protection Laws, and are consistent with the obligations imposed on Backseat Fleet in this clause 10;</Typography>
                      <Typography component='p' display='block'>(ii) shall remain responsible for the acts and omission of any such Sub-Processor as if they were the acts and omissions of Backseat Fleet; and</Typography>
                      <Typography component='p' display='block'>(iii) shall at least ten (10) business days prior to authorising any new Sub-Processor to process Protected Data provide notice to the Customer of the update to the Sub-Processor List. If the Customer objects to such 
                      changes within ten (10) business days of receipt of such notice Backseat Fleet shall work with the Customer in good faith to make available a commercially reasonable change in the provision of the Software which avoids the use of that proposed Sub-Processor and,
                       where such a change cannot be made and Backseat Fleet chooses to retain the Sub-Processor, Backseat Fleet shall notify the Customer at least ten (10) business days prior to the authorisation of the Sub-Processor and the Customer may choose to discontinue the Services 
                       and terminate the relevant portion/parts of the Services which require the use of the proposed Sub-Processor immediately upon written notice to Backseat Fleet, such notice to be given by the Customer within thirty (30) business days of having been so notified by 
                       Backseat Fleet.</Typography>
                      <Typography component='p' display='block'>10.11 Either party may, at any time on not less than 30 days' notice, revise this clause 10 by replacing it with any applicable controller to processor standard clauses or similar terms forming part of an applicable certification 
                      scheme (which shall apply when replaced by attachment to this Agreement).</Typography>

                      <Typography component='h3' display='block'> 11 General</Typography>
                      <Typography component='p' display='block'>11.1 This Agreement shall not be assigned in whole or in part by the Customer without the prior written consent of Backseat Fleet.</Typography>
                      <Typography component='p' display='block'>11.2 If either party is affected by an event of Force Majeure (any circumstances beyond their reasonable control including without limitation all industrial actions and natural disasters) it shall notify the other immediately of the same and the party shall be deemed not to be in breach of this Agreement in so far as any failure to comply with this Agreement is attributable to the event of Force Majeure.</Typography>
                      <Typography component='p' display='block'>11.3 Confidential information concerning either party's business made available to the other party will not be disclosed by the other party or any associate to any third party whatsoever (save to the extent that it is in the public domain or that such disclosure accords with the terms of Backseat Fleets' Privacy Policy) without written consent of the party who supplied the confidential information unless otherwise required by law.</Typography>
                      <Typography component='p' display='block'>11.4 If any provision of this Agreement (or part of any provision) is found by any court or other authority of competent jurisdiction to be invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed not to form part of this Agreement, and the validity and enforceability of the other provisions of this Agreement shall not be affected.</Typography>
                      <Typography component='p' display='block'>11.5 This Agreement represents the entire agreement and understanding of the Parties in respect of the subject matter hereof and supersedes all prior undertakings and representations whether written or oral and this Agreement may only be modified if such modification is in writing and signed by the Company and the Customer.</Typography>
                      <Typography component='p' display='block'>11.6 Failure by either Party to exercise or enforce any right conferred by this Agreement shall not be deemed to be a waiver of any such right nor operate as to bar the exercise or enforcement thereof or any other right on a later occasion.</Typography>
                      <Typography component='p' display='block'>11.7 Any notice, invoice or other document which may be given by either Party under this Agreement shall be deemed to have been given if left or sent by post or facsimile transmission (confirming the same by post) if sent to an address notified by the other party in writing as an address to which notices invoices or other documents may be sent.</Typography>
                      <Typography component='p' display='block'>11.8 Backseat Fleets' address for service of any notice hereunder shall be such address as appears on the face of this Agreement or on the last invoice rendered to the Customer or such other address as may be prescribed by Backseat Fleet and notified to the Customer in writing for that purpose.</Typography>
                      <Typography component='p' display='block'>11.9 This Agreement shall be governed by and construed and interpreted in accordance with English Law and the Parties hereby submit to the exclusive jurisdiction of the English Courts.</Typography>
                      <Typography component='p' display='block'>11.10 A third party who is not a party to this Agreement shall not have any rights under or in connection with it under Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Agreement.</Typography>
                      
                      <Typography component='h3' display='block'>Schedule 1:</Typography>
                      <Typography component='p' display='block'>Part A</Typography>
                      <Typography component='p' display='block'>Data processing details</Typography>
                      <Typography component='p' display='block'>Processing of the Protected Data by Backseat Fleet under the Agreement shall be for the subject-matter, duration, nature and purposes and involve the types of personal data and categories of Data Subjects set out in this Part A.</Typography>
                      <Typography component='p' display='block'>1. Subject Matter, Nature, Purpose and Duration of processing:
Backseat Fleet will process Protected Data to provide Services to you. The processing of Protected Data shall be for the term of the Contract Period or until Backseat Fleets' legal obligations in relation to the processing of the Protected Data have ceased.</Typography>
                      <Typography component='p' display='block'>2. Type of Personal Data:</Typography>
                      <Typography component='p' display='block'>2.1 The Software provided by Backseat Fleet, or as may be further developed, upgraded or supplemented from time to time, generally includes (but is not limited to) portals for drivers, fleet managers and other types of users as determined by the Customer, computer programs or applications, integration with a number of telematics systems, information regarding vehicle and driver management, fuel management, driver policy management, grey fleet assistance, accident management, fleet maintenance, document storage, plant and equipment, advanced reporting, driver risk management, HGV module, HGV maintenance module, advanced HGV reporting and other types of information as determined by the Customer.</Typography>
                      <Typography component='p' display='block'>2.2 The types of Personal Data that Backseat Fleet processes to provide the Services depend on factors such as (but not limited to):</Typography>
                      <Typography component='p' display='block'>(a) the features selected by the Customer as part of the Software;</Typography>
                      <Typography component='p' display='block'>(b) the data fields and types of detail added to the Software and used by the Customer as part of the selected system features;</Typography>
                      <Typography component='p' display='block'>(c) the types of data input directly into the Software’s system by the Customer or by Backseat Fleet under the instruction of the Customer; and</Typography>
                      <Typography component='p' display='block'>(d) the people added by the Customer as users of the Software.
2.3 In accordance with the Agreement to provide the Services and Software to the Customer Backseat Fleet may process (but not limited to) the following types of Personal Data depending on the factors mentioned in paragraph 2.2 above:</Typography>
                      <Typography component='p' display='block'>(a) In relation to drivers: name of driver, date of birth, place of birth, private address, private telephone number, email address, disciplinary and grievance, accident reports, right to work checks (passport details) National Insurance Number, name of supervisor, work location, references, performance reports, insurance documents, driving licence details, records of medical data and incidents.</Typography>
                      <Typography component='p' display='block'>(b) any other types of Personal Data collected by the Customer which may be processed by Backseat Fleet for the purposes of providing the Software and Services.</Typography>
                      <Typography component='p' display='block'>3. Categories of Data Subjects:</Typography>
                      <Typography component='p' display='block'>3.1 As noted in paragraph 2.2 the types of Data Subjects for which Personal Data may be processed depends on the factors mentioned in paragraph 2.2 and Backseat Fleet does not control the types of Data Subjects for which or from which the Customer may collect or process Personal Data. Subject to that it is confirmed that Backseat Fleet may typically process (but not limited to) Protected Data in relation to the following data subjects:</Typography>
                      <Typography component='p' display='block'>(a) the Customer's drivers;</Typography>
                      <Typography component='p' display='block'>(b) the Customer's employees;</Typography>
                      <Typography component='p' display='block'>(c) the Customer's job applicants; and</Typography>
                      <Typography component='p' display='block'>(d) any other categories of data subjects which may be added by the Customer for the use of the Software and therefore in relation to who Backseat Fleet may process Personal Data to provide the Software to the Customer.</Typography>
                      <Typography component='p' display='block'>4. Specific processing instructions:
Backseat Fleet shall process Protected Data as reasonably necessary for the provision of the Software and Services arising from the Agreement and in accordance with the Customer's written instructions. If the Customer has any specific processing instructions, the Customer is required to notify Backseat Fleet in writing so that Backseat Fleet may process the Protected Data in accordance with those specific instructions.</Typography>
                      <Typography component='p' display='block'>Part B</Typography>
                      <Typography component='p' display='block'>Technical and organisational security measures</Typography>
                      <Typography component='p' display='block'>1. Backseat Fleet shall implement and maintain the following technical and organisational security measures to protect the Protected Data:</Typography>
                      <Typography component='p' display='block'>1.1 In accordance with the Applicable Data Protection Laws, taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of the Protected Data to be carried out under or in connection with the Agreement, as well as the risks of varying likelihood and severity for the rights and freedoms of natural persons and the risks that are presented by the processing, especially from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to the Protected Data transmitted, stored or otherwise processed, Backseat Fleet shall implement appropriate technical and organisational security measures appropriate to the risk.</Typography>
                      <Typography component='p' display='block'> 1.2 The Customer is, however, responsible for:</Typography>
                      <Typography component='p' display='block'>(a) maintaining the security of access to data ;</Typography>
                      <Typography component='p' display='block'>(b) ensuring that data held at the Customer’s premises is adequately backed up;</Typography>
                      <Typography component='p' display='block'>(c) ensuring the correct environment is deployed to get the safest and fastest possible experience from Backseat Fleets' software products; and</Typography>
                      <Typography component='p' display='block'>(d) protecting its systems from any bugs in any third-party software or other software, viruses, corrupt data and back-up failures.</Typography>
                    </Grid>

                </Grid>
              
            </Grid>
        </ModalDialog>
        </Modal>
    )
}